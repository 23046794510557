<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-key"></i> Usuário</h5>
                    <btn-refresh @click="atualizar()" v-if="acao === 'DETALHAR' || acao === 'ATUALIZAR'"></btn-refresh>
                </div>
                <erros-box :erros="erros"></erros-box>
                <div class="p-formgrid p-grid p-fluid">
                    <div class="p-field p-col-12 p-md-2">
                        <label>Tipo Usuário</label>
                        <Dropdown v-model="tipoUsuario" :options="tiposUsuario" optionLabel="nome" optionValue="id" />
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                        <label>Tipo Pessoa</label>
                        <Dropdown v-model="tipoPessoa" :options="tiposPessoa" optionLabel="nome" optionValue="id" :disabled="tipoUsuario == 0" />
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label>Login</label>
                        <InputText type="text" v-model="login" :disabled="desabilitaForm" @input="v$.login.$touch()" />
                        <small class="p-error" v-if="v$.login.$error">O login é obrigatório</small>
                    </div>
                    <div class="p-field p-col-12 p-md-5">
                        <label>Email</label>
                        <InputText type="text" v-model="email" :disabled="desabilitaForm" @input="v$.email.$touch()" />
                        <small class="p-error" v-if="v$.email.$error">O email é obrigatório</small>
                    </div>
                    <div class="p-field p-col-12 p-md-8">
                        <label>Nome</label>
                        <InputText type="text" v-model="nome" :disabled="desabilitaForm" @input="v$.nome.$touch()" />
                        <small class="p-error" v-if="v$.nome.$error">O nome é obrigatório</small>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <label>Telefone</label>
                        <InputMask v-model="telefone" mask="(99)99999999?9" :unmask="true" :disabled="desabilitaForm" @keypress="v$.telefone.$touch()" />
                        <small class="p-error" v-if="v$.telefone.$error">O telefone é obrigatório</small>
                    </div>
                </div>
                <slot name="botoes"></slot>
                <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" v-if="!desabilitaForm" />
                <Button label="Salvar" icon="pi pi-check-circle" class="p-ml-2 p-button-primary" @click="confirmarSalvar()" v-if="!desabilitaForm" :disabled="v$.$invalid" />
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        usuario: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar', 'pesquisarCpf'],

    data() {
        return {
            cpf: null,
            nome: null,
            login: null,
            email: null,
            telefone: null,
            tipoUsuario: 0,
            tipoPessoa: 0,
            tiposUsuario: [
                { id: 0, nome: 'INTERNO' },
                { id: 1, nome: 'EXTERNO' },
            ],
            tiposPessoa: [
                { id: 0, nome: 'FÍSICA' },
                { id: 1, nome: 'JURÍDICA' },
            ],
        };
    },

    validations() {
        return {
            nome: { required },
            login: { required },
            email: { required, email },
            telefone: { required, minLength: minLength(10) },
        };
    },

    methods: {
        preencher() {
            this.tipoUsuario = this.usuario?.tipo;
            this.tipoPessoa = this.usuario?.tipoPessoa;
            this.nome = this.usuario?.nome;
            this.login = this.usuario?.login;
            this.email = this.usuario?.email;
            this.telefone = this.usuario?.telefone;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o usuário?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let usuarioDto = {
                tipo: this.tipoUsuario,
                tipoPessoa: this.tipoPessoa,
                nome: this.nome,
                login: this.login,
                email: this.email,
                telefone: this.telefone,
            };
            this.$emit('salvar', usuarioDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },
    },

    computed: {
        desabilitaForm() {
            return this.acao === 'DETALHAR';
        },

        desabilitaCpf() {
            return this.acao === 'ATUALIZAR';
        },
    },

    watch: {
        usuario() {
            this.preencher();
        },

        tipoUsuario() {
            if (this.tipoUsuario == 0) {
                this.tipoPessoa = 0;
            }
        },
    },
};
</script>
