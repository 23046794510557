<template>
    <usuario-form
        acao="INSERIR"
        @salvar="inserir($event)"
        :cancelar="cancelar"
        :erros="erros"
        :usuario="usuario"
        @pesquisarCpf="pesquisarCpf($event)"
    ></usuario-form>
</template>

<script>
import UsuariosServices from './services';
import UsuarioForm from './UsuarioForm';

export default {
    components: {
        UsuarioForm,
    },

    data() {
        return {
            erros: [],
            usuario: null,
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: 'Usuarios' });
        },

        inserir(usuarioInserido) {
            this.$store.dispatch('addRequest');
            let usuarioInseridoDto = {
                tipo: usuarioInserido.tipo,
                tipoPessoa: usuarioInserido.tipoPessoa,
                nome: usuarioInserido.nome,
                login: usuarioInserido.login,
                email: usuarioInserido.email,
                telefone: usuarioInserido.telefone,
            };
            UsuariosServices.inserir(usuarioInseridoDto).then(response => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Usuário inserido com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        pesquisarCpf(cpf) {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterPorCpf(cpf).then(response => {
                if (response && response.success && response.data != '') {
                    this.usuario = response.data;
                } else {
                    this.usuario = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(usuarioId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Usuarios_Detalhar',
                params: {
                    id: usuarioId,
                },
            });
        },
    },
};
</script>
